import { useRouter } from 'next/router';

import { LinkTo } from '../../components/shared';

import styles from './404.module.scss';

const Custom404 = () => {
  const router = useRouter();

  const handleLinkButtonClick = () => {
    router.replace('/');
  };

  return (
    <div className={styles['custom-404__wrapper']}>
      <h1>404</h1>
      <h2>Страница не найдена.</h2>
      <LinkTo href="/" onClick={handleLinkButtonClick} className="ant-btn ant-btn-primary">
        Перейти на главную
      </LinkTo>
    </div>
  );
};

export default Custom404;
